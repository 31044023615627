import React, { useState, useEffect } from 'react'
import Modal from 'react-responsive-modal'
import { ButtonRed } from '../../assets/common/ButtonRed/ButtonRed'
import styles from './RatingParams.module.scss'

export const EditParamsModal = ({open, year, closeModal, getRatingCriterion, criterions}) => {
  
  const [data, setData] = useState([])

  useEffect(() => {
    if (!open) return;
    getRatingCriterion(year)
  }, [open])

  useEffect(() => {
    setData(criterions || [])
  }, [criterions])
  
  const updateData = (parent, list) => {
    const newData = data.filter(el => el.parent !== parent)
    setData([...newData, ...list])
  }

  return (
    <Modal
      open={open}
      classNames={{ modal: styles.criterionsModal }}
      showCloseIcon={false}
    >
      <h2>Настройка критериев</h2>
      {data.length && <EditableList parent={0} criterions={data} updateData={updateData}/>}
      <div className={styles.buttonsBlock}>
        <ButtonRed onClick={closeModal}>Сохранить</ButtonRed>
        <ButtonRed onClick={closeModal}>Закрыть</ButtonRed>
      </div>
    </Modal>
  )
}


const EditableList = ({parent, criterions, updateData}) => {
  
  const [items, setItems] = useState([])
  const [draggingItem, setDraggingItem] = useState(null)

  useEffect(() => {
    setItems(criterions.filter(el => el.parent == parent))
  }, [parent])
  

  const onDragStart = (item) => (e) => {
    e.stopPropagation()
    setDraggingItem(item)
  }

  const onDragEnter = (index) => (e) => {
    e.preventDefault()
    if (draggingItem !== null && index !== items.indexOf(draggingItem)) {
      const newItems = [...items]
      const item = newItems.splice(items.indexOf(draggingItem), 1)[0]
      newItems.splice(index, 0, item)
      setItems(newItems)
      updateData(parent, newItems)
    }
  }    

  const onDragEnd = () => {
    setDraggingItem(null)
  }


  const renderItem = ({criterion, id}, index) => {
    return (
      <li style={{opacity: draggingItem === items[index] ? 0.5 : 1}}>
        <p
          draggable
          onDragStart={onDragStart(items[index])}
          onDragEnter={(e) => onDragEnter(index)(e)}
          onDragEnd={onDragEnd}
          onDragOver={e => e.preventDefault()}
        >
          {criterion}
        </p>
        <EditableList parent={id} criterions={criterions} updateData={updateData} />
      </li>
    )
  }

  return (
    <ul>{items.map(renderItem)}</ul>
  )
}

export default EditParamsModal